import React from 'react'
import {
  flockTheme,
  GridForm,
  LibraryThemeProvider,
  Checkbox,
} from '@flock/shared-ui'
import {
  Box,
  Table,
  TableCell,
  TableRow,
  ThemeProvider,
  Typography,
  Grid,
  Button,
} from '@mui/material'
import { DataTable, LoadingCard } from '@flock/flock-component-library'
import { RouteComponentProps } from '@reach/router'

import useCreateSalesforceOrderPage, {
  legalEntityColumns,
  selectedLegalEntityColumns,
} from './useCreateSalesforceOrderPage'
import CreateLegalEntityModal from '../CreateLegalEntity/CreateLegalEntityModal'
import OrderConfirmationModal from '../OrderConfirmationModal/OrderConfirmationModal'
import { FlockUnit } from './createSalesforceOrderPageTypes'
import { SALESFORCE_URL } from '../../../constants'

const TextTableCell = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      verticalAlign: 'top',
    }}
  >
    <Typography variant="body2">{children}</Typography>
  </TableCell>
)

const CreateSalesforceOrderPage = (_: RouteComponentProps) => {
  const presentationalProps = useCreateSalesforceOrderPage()
  const {
    loading,
    order,
    orderConfirmationOpen,
    scenarioId,
    createOrderLoading,
    createTestCALoading,
    legalEntities,
    selectedLegalEntities,
    legalEntityTableOptions,
    selectedLegalEntityTableOptions,
    inputConfigs,
    onSubmit,
    testCA,
    closeOrderConfirmation,
    confirmAddress,
    addressesToConfirm,
    isTestDownload,
    setIsTestDownload,
  } = presentationalProps

  if (loading) {
    return <LoadingCard text="Loading orders..." />
  }

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <Box display="flex" flexDirection="column" gap="48px" pt="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h2">Create Order - {scenarioId}</Typography>
            <Box>
              <Button
                variant="secondary"
                size="smallForm"
                onClick={() => {
                  window.open(
                    `${SALESFORCE_URL}/lightning/r/Scenario__c/${scenarioId}/view`,
                    '_blank'
                  )
                }}
              >
                Open in Salesforce
              </Button>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="h3">Address Confirmation</Typography>
            <Typography>
              Please confirm that the following addresses are correct. If any
              are not, please contact the engineering team.
            </Typography>
            <Table>
              <TableRow>
                <TextTableCell>SF Address</TextTableCell>
                <TextTableCell>SF Units</TextTableCell>
                <TextTableCell>Flock Address</TextTableCell>
                <TextTableCell>Flock Units</TextTableCell>
                <TextTableCell>Has Lease</TextTableCell>
                <TextTableCell>Confirm</TextTableCell>
              </TableRow>
              {addressesToConfirm.map((address, idx) => (
                <TableRow
                  key={address.parentFlockAddressUuid}
                  sx={{
                    backgroundColor: address.confirmed
                      ? 'green0.main'
                      : 'softGold.main',
                  }}
                >
                  <TextTableCell>
                    {address.salesforceFormattedAddressStreet}
                    <br />
                    {address.salesforceFormattedAddressCityStateZip}
                  </TextTableCell>
                  <TextTableCell>
                    {address.salesforceUnits.map((unit: string) => (
                      <Typography
                        key={`${address.parentFlockAddressUuid}-${unit}`}
                        variant="body2"
                      >
                        {unit}
                        <br />
                      </Typography>
                    ))}{' '}
                  </TextTableCell>
                  <TextTableCell>
                    {address.flockFormattedAddressStreet}
                    <br />
                    {address.flockFormattedAddressCityStateZip}
                  </TextTableCell>
                  <TextTableCell>
                    {address.flockUnits.map((unit: FlockUnit) => (
                      <Typography
                        key={`${address.parentFlockAddressUuid}-${unit.name}`}
                        variant="body2"
                      >
                        {unit.name}
                        <br />
                      </Typography>
                    ))}
                  </TextTableCell>
                  <TextTableCell>
                    {address.hasLease.map(
                      (hasLease: boolean, subIdx: number) => (
                        <Typography
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${address.parentFlockAddressUuid}-hasLease-${subIdx}`}
                          variant="body2"
                        >
                          {hasLease ? 'Yes' : 'No'}
                          <br />
                        </Typography>
                      )
                    )}
                  </TextTableCell>
                  <TextTableCell>
                    <Button
                      variant="secondary"
                      size="smallForm"
                      onClick={() => confirmAddress(idx)}
                      disabled={address.confirmed}
                    >
                      {address.confirmed ? 'Confirmed' : 'Confirm'}
                    </Button>
                  </TextTableCell>
                </TableRow>
              ))}
            </Table>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" flexDirection="column" width="50%">
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Add Legal Entities</Typography>
                <Box>
                  <CreateLegalEntityModal />
                </Box>
              </Box>
              {loading ? (
                <LoadingCard text="Loading..." />
              ) : (
                <DataTable
                  title=""
                  data={legalEntities}
                  columns={legalEntityColumns}
                  options={legalEntityTableOptions as any}
                />
              )}
            </Box>
            <Box display="flex" flexDirection="column" width="40%">
              <Typography variant="h3">Selected Legal Entities</Typography>
              {loading ? (
                <LoadingCard text="Loading..." />
              ) : (
                <DataTable
                  title=""
                  data={selectedLegalEntities}
                  columns={selectedLegalEntityColumns}
                  options={selectedLegalEntityTableOptions as any}
                />
              )}
            </Box>
          </Box>
          <Checkbox
            data-testid="isTestDownload"
            label="Test Contribution Agreement?"
            checked={isTestDownload}
            onChange={() => setIsTestDownload(!isTestDownload)}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          >
            <Grid container>
              <GridForm
                onSubmit={isTestDownload ? testCA : onSubmit}
                inputConfigs={inputConfigs}
                loading={loading || createOrderLoading || createTestCALoading}
                ctaText={isTestDownload ? 'Test CA' : 'Submit'}
                gridProps={{
                  spacing: 3,
                }}
                ctaBoxProps={{
                  pb: '32px',
                }}
              />
            </Grid>
          </Box>
          {orderConfirmationOpen && (
            <OrderConfirmationModal
              isOpen={orderConfirmationOpen}
              close={() => closeOrderConfirmation()}
              orderResponse={order}
              useNewTheme
            />
          )}
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default CreateSalesforceOrderPage
