import { FormattedTextField } from '@flock/shared-ui'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import {
  SowItemSolution,
  SowCostCatalog,
  adjustmentOptions,
  uomOptions,
} from '../individualSowProjectPageTypes'
import LabelledField from '../LabelledField'

const autocompleteSlotProps = {
  paper: {
    sx: {
      '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
          fontSize: '16px',
        },
      },
    },
  },
}

const UrgencyButton = (props: {
  label: string
  selected: boolean
  onClick: () => void
}) => {
  const { label, selected, onClick } = props
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="8px 12px"
      gap="4px"
      width="100%"
      sx={{
        backgroundColor: selected ? 'green2.main' : 'green1.main',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        '&:hover': {
          backgroundColor: 'green2.main',
        },
      }}
      onClick={onClick}
    >
      <Typography variant="c1">{label}</Typography>
    </Box>
  )
}

type SowModalSolutionRowProps = SowItemSolution & {
  keywords: string[]
  onRemoveSolution: () => void
  onUpdateSolution: (solution: SowItemSolution) => void
  costCatalog: SowCostCatalog
  solutionOptions: string[]
}

const SowModalSolutionRow = (props: SowModalSolutionRowProps) => {
  const {
    uuid,
    itemCode,
    category,
    solution,
    description,
    unitOfMeasurement,
    unitPrice,
    quantity,
    urgency,
    adjustment,
    keywords,
    costCatalog,
    solutionOptions: deficiencySolutions,
    onRemoveSolution,
    onUpdateSolution,
  } = props

  const adjustmentObject = adjustmentOptions.find(
    (item) => item.value === adjustment
  )

  const [curCategory, setCurCategory] = useState(category)
  const [curSolution, setCurSolution] = useState(solution)
  const [curDescription, setCurDescription] = useState(description)
  const [curUnitOfMeasurement, setCurUnitOfMeasurement] =
    useState(unitOfMeasurement)
  const [curUnitPrice, setCurUnitPrice] = useState(unitPrice || 0)
  const [curQuantity, setCurQuantity] = useState(quantity || 1)
  const [curUrgency, setCurUrgency] = useState<string>(urgency)
  const [curAdjustment, setCurAdjustment] = useState(
    adjustmentObject || adjustmentOptions[0]
  )
  const [curTotalCost, setCurTotalCost] = useState(0)

  // For flagging if the set solution is the same as one in the costbook
  const [curSolutionCode, setCurSolutionCode] = useState(itemCode)
  const [originalSolution, setOriginalSolution] = useState('')
  const [originalCostPerItem, setOriginalCostPerItem] = useState(0)
  const [originalUom, setOriginalUom] = useState('')
  const [originalDescription, setOriginalDescription] = useState('')

  const [flagCostPerItemDifferent, setFlagCostPerItemDifferent] =
    useState(false)
  const [flagDescriptionDifferent, setFlagDescriptionDifferent] =
    useState(false)
  const [flagUomDifferent, setFlagUomDifferent] = useState(false)
  const [flagSolutionDifferent, setFlagSolutionDifferent] = useState(false)

  useEffect(() => {
    // Sets the current originals
    if (!curCategory || !costCatalog[curCategory]) {
      setOriginalCostPerItem(0)
      setOriginalSolution('')
      setOriginalUom('')
      setOriginalDescription('')
      return
    }
    const originalItem = costCatalog[curCategory].find(
      (catalogRow) => catalogRow.itemCode === curSolutionCode
    )

    if (originalItem) {
      setOriginalCostPerItem(originalItem.price)
      setOriginalSolution(originalItem.solution)
      setOriginalUom(originalItem.uom)
      setOriginalDescription(originalItem.description)
    }
  }, [curSolutionCode, costCatalog, curCategory])

  const categoryOptions = useMemo(() => Object.keys(costCatalog), [costCatalog])
  const solutionOptions = useMemo(() => {
    if (!curCategory || !costCatalog[curCategory]) return []
    return Array.from(
      new Set(
        costCatalog[curCategory]
          .map((item) => item.solution)
          .sort((a, b) => {
            // Check if 'a' or 'b' contains any keyword
            const aHasKeyword = keywords.some((keyword) =>
              a.toLowerCase().includes(keyword.toLowerCase())
            )
            const bHasKeyword = keywords.some((keyword) =>
              b.toLowerCase().includes(keyword.toLowerCase())
            )

            // Sort by whether they contain keywords
            if (aHasKeyword && !bHasKeyword) return -1
            if (!aHasKeyword && bHasKeyword) return 1

            // check if 'a' or 'b' are in the deficiencySolutions
            const aInSolutions = deficiencySolutions.includes(a)
            const bInSolutions = deficiencySolutions.includes(b)

            // Sort by whether they are in the deficiencySolutions
            if (aInSolutions && !bInSolutions) return -1
            if (!aInSolutions && bInSolutions) return 1

            return 0 // No change in order if both or neither have keywords
          })
      )
    )
  }, [curCategory, costCatalog, keywords, deficiencySolutions])

  const descriptionOptions = useMemo(() => {
    if (!curCategory || !costCatalog[curCategory]) return []
    // The description options are based on the currently selected solution
    // We find every solution in the costCatalog that matches the current solution
    // and return the descriptions for those solutions
    const options = costCatalog[curCategory]
      .filter((item) => item.solution === curSolution && item.description)
      .map((item) => item.description)

    return options
  }, [curCategory, curSolution, costCatalog, deficiencySolutions])

  const onSetCurSolution = (newSolution: string) => {
    // Check if new solution exists in the costCatalog
    setCurSolution(newSolution)

    if (!curCategory || !costCatalog[curCategory]) return
    if (
      costCatalog[curCategory].find((item) => item.solution === newSolution)
    ) {
      const item = costCatalog[curCategory].find(
        (catalogRow) => catalogRow.solution === newSolution
      )
      if (!item) return

      if (item.price) {
        setCurUnitPrice(item.price)
        setCurUnitOfMeasurement(item.uom)
        setCurSolutionCode(item.itemCode)
        setCurDescription(item.description)
      }
    }
  }

  const onSetCurDescription = (newDescription: string) => {
    // Check if the current solution exists in the catalog

    setCurDescription(newDescription)

    if (!curCategory || !costCatalog[curCategory]) return
    const item = costCatalog[curCategory].find(
      (catalogRow) =>
        catalogRow.solution === curSolution &&
        catalogRow.description === newDescription
    )

    if (item?.price) {
      setCurSolution(item.solution)
      setCurUnitPrice(item.price)
      setCurUnitOfMeasurement(item.uom)
      setCurSolutionCode(item.itemCode)
    }
  }

  useEffect(() => {
    setCurCategory(category)
  }, [category])

  useEffect(() => {
    const newTotalCost = curUnitPrice * curQuantity * curAdjustment.value
    setCurTotalCost(newTotalCost)
  }, [curUnitPrice, curQuantity, curAdjustment])

  useEffect(() => {
    if (originalSolution && curSolution !== originalSolution) {
      setFlagSolutionDifferent(true)
    } else {
      setFlagSolutionDifferent(false)
    }
  }, [curSolution, originalSolution])

  useEffect(() => {
    if (curUnitPrice !== originalCostPerItem) {
      setFlagCostPerItemDifferent(true)
    } else {
      setFlagCostPerItemDifferent(false)
    }
  }, [curUnitPrice, originalCostPerItem])

  useEffect(() => {
    if (originalDescription && curDescription !== originalDescription) {
      setFlagDescriptionDifferent(true)
    } else {
      setFlagDescriptionDifferent(false)
    }
  }, [curDescription, originalDescription])

  useEffect(() => {
    if (curUnitOfMeasurement !== originalUom) {
      setFlagUomDifferent(true)
    } else {
      setFlagUomDifferent(false)
    }
  }, [curUnitOfMeasurement, originalUom])

  // Update the solution with the new values
  useEffect(() => {
    onUpdateSolution({
      uuid,
      category: curCategory,
      solution: curSolution,
      description: curDescription,
      unitOfMeasurement: curUnitOfMeasurement,
      unitPrice: curUnitPrice,
      quantity: curQuantity,
      urgency: curUrgency,
      adjustment: curAdjustment.value,
      itemCode: curSolutionCode,
    })
  }, [
    curCategory,
    curSolution,
    curDescription,
    curUnitOfMeasurement,
    curUnitPrice,
    curQuantity,
    curUrgency,
    curAdjustment,
    curSolutionCode,
  ])

  const flagged =
    flagCostPerItemDifferent ||
    flagDescriptionDifferent ||
    flagUomDifferent ||
    flagSolutionDifferent

  return (
    <Box display="flex" flexDirection="row" gap="12px" width="100%">
      <Box
        width="4px"
        height={flagged ? '260px' : '224px'}
        sx={{ backgroundColor: 'green4.main' }}
      />
      <Box display="flex" flexDirection="column" gap="16px" width="100%">
        <Box display="flex" gap="16px">
          <LabelledField label="Category" width="320px">
            <Autocomplete
              value={curCategory}
              onChange={(_, value) => setCurCategory(value)}
              onInputChange={(_, newValue) => setCurCategory(newValue)}
              options={categoryOptions}
              disableClearable
              placeholder="Category"
              freeSolo
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField {...params} variant="filled" fullWidth />
              )}
            />
          </LabelledField>

          <LabelledField
            label="Solution"
            tooltip={
              flagSolutionDifferent ? (
                <>
                  Costbook solution for <b>{originalSolution}</b> is{' '}
                  <b>{originalSolution}</b>
                </>
              ) : (
                ''
              )
            }
          >
            <Autocomplete
              value={curSolution}
              onChange={(_, value) => onSetCurSolution(value)}
              onInputChange={(_, newValue, reason) =>
                reason === 'input' ? onSetCurSolution(newValue || '') : null
              }
              options={solutionOptions}
              disableClearable
              placeholder="Solution"
              freeSolo
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField {...params} variant="filled" fullWidth />
              )}
            />
          </LabelledField>
          <Box
            display="flex"
            justifySelf="flex-end"
            alignSelf="flex-end"
            justifyContent="center"
            alignItems="center"
            p="12px 8px"
            height="48px"
            sx={{
              cursor: 'pointer',
              color: 'errorRed.main',
              backgroundColor: 'gray2.main',
              borderRadius: '8px',
              transition: 'background-color 0.2s',

              '&:hover': {
                backgroundColor: 'gray3.main',
              },
            }}
            onClick={onRemoveSolution}
          >
            <Typography variant="c1" noWrap>
              DELETE ITEM
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap="16px">
          <LabelledField
            label="Description"
            tooltip={
              flagDescriptionDifferent ? (
                <>
                  Costbook description for <b>{originalSolution}</b> is{' '}
                  <b>{originalDescription}</b>
                </>
              ) : (
                ''
              )
            }
          >
            <Autocomplete
              value={curDescription}
              onChange={(_, value) => setCurDescription(value)}
              onInputChange={(_, newValue, reason) =>
                reason === 'input' ? onSetCurDescription(newValue || '') : null
              }
              options={descriptionOptions}
              disableClearable
              placeholder="Description"
              freeSolo
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  multiline
                  rows={1.5}
                />
              )}
            />
          </LabelledField>
          <Box display="flex" flexDirection="column" gap="12px">
            <Box display="flex" gap="16px">
              <LabelledField
                label="UOM"
                width="80px"
                tooltip={
                  flagUomDifferent ? (
                    <>
                      Costbook UOM for <b>{originalSolution}</b> is{' '}
                      <b>{originalUom}</b>
                    </>
                  ) : (
                    ''
                  )
                }
              >
                <Autocomplete
                  value={curUnitOfMeasurement}
                  onChange={(_, value) => setCurUnitOfMeasurement(value || '')}
                  options={uomOptions}
                  disableClearable
                  placeholder="EA"
                  slotProps={autocompleteSlotProps}
                  sx={{
                    '& .MuiAutocomplete-endAdornment': {
                      display: 'block',
                      top: 'calc(50% - 16px)',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" fullWidth />
                  )}
                />
              </LabelledField>
              <LabelledField label="Qty" width="96px">
                <FormattedTextField
                  variant="filled"
                  format="number"
                  placeholder="0"
                  value={curQuantity}
                  onChange={(e) =>
                    setCurQuantity(
                      e.target.value ? parseFloat(e.target.value) : 0
                    )
                  }
                />
              </LabelledField>
              <LabelledField
                label="Cost / item"
                width="120px"
                tooltip={
                  flagCostPerItemDifferent ? (
                    <>
                      Costbook cost for <b>{originalSolution}</b> is{' '}
                      <b>${originalCostPerItem.toFixed(2)}</b>
                    </>
                  ) : (
                    ''
                  )
                }
              >
                <FormattedTextField
                  variant="filled"
                  format="dollarsAndCents"
                  placeholder="$0"
                  value={curUnitPrice}
                  onChange={(e) =>
                    setCurUnitPrice(
                      e.target.value ? parseFloat(e.target.value) : 0
                    )
                  }
                />
              </LabelledField>
              <LabelledField label="Adj." width="96px">
                <Autocomplete
                  value={curAdjustment}
                  onChange={(_, value) =>
                    setCurAdjustment(value || adjustmentOptions[0])
                  }
                  options={adjustmentOptions}
                  disableClearable
                  placeholder="100%"
                  slotProps={autocompleteSlotProps}
                  sx={{
                    '& .MuiAutocomplete-endAdornment': {
                      display: 'block',
                      top: 'calc(50% - 16px)',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" fullWidth />
                  )}
                />
              </LabelledField>
              <LabelledField label="Cost" width="160px">
                <FormattedTextField
                  variant="filled"
                  format="dollarsAndCents"
                  placeholder="$0"
                  onChange={() => {}}
                  value={curTotalCost}
                  sx={{
                    borderBottom: 'none',
                  }}
                  disabled
                />
              </LabelledField>
            </Box>
            <Box display="flex" gap="8px">
              <UrgencyButton
                label="Health & Safety"
                selected={curUrgency === 'health_and_safety'}
                onClick={() => {
                  setCurUrgency('health_and_safety')
                }}
              />
              <UrgencyButton
                label="1st Turn"
                selected={curUrgency === 'first_turn'}
                onClick={() => {
                  setCurUrgency('first_turn')
                }}
              />
              <UrgencyButton
                label="0-3 Years"
                selected={curUrgency === 'zero_to_three_years'}
                onClick={() => {
                  setCurUrgency('zero_to_three_years')
                }}
              />
              <UrgencyButton
                label="Not-Immediate"
                selected={curUrgency === 'non_immediately_addressable'}
                onClick={() => {
                  setCurUrgency('non_immediately_addressable')
                }}
              />
            </Box>
          </Box>
        </Box>

        {flagged && (
          <Box display="flex" gap="16px">
            <Typography variant="p4" color="errorRed.main">
              This solution deviates from the costbook ({itemCode}).
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SowModalSolutionRow
