import React, { useEffect, useState } from 'react'
import { DownloadSimple } from 'phosphor-react'
import {
  Typography,
  DataTable,
  Button,
  CircularProgress,
  LoadingCard,
  useSnackbar,
  PopoverMenu,
  flockColors,
} from '@flock/flock-component-library'
import {
  AdminDeleteLegalEntitiesDocumentMutationDocument,
  AdminGetDocumentDownloadUrlDocument,
  AdminGetDocumentsDocument,
  AdminGetInvestmentInfoForLegalEntityDocument,
  AdminGetInvestmentTransactionInfoForLegalEntityDocument,
  AdminGetLegalEntitiesDistributionsDocument,
  AdminGetLegalEntityDocument,
  AdminOrderOnboardingV2SearchOrdersV2Document,
  Core_Distribution,
  Core_Distribution as Distribution,
  Core_InvestmentTransaction,
  Core_InvestmentTransaction as InvestmentTransaction,
  Core_LegalEntityDocument,
  Core_LegalEntityDocument as LegalEntityDocument,
  Core_OrderV2,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { useMutation, useQuery } from '@apollo/client'
import { TableRow, TableCell, Grid } from '@mui/material'

import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { formatIntegerCents, formatPhoneNumber } from '@flock/utils'

import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CreateDocumentModal from './CreateDocumentModal'
import { formatDateString, formatCents } from './utils'
import CreateDistributionsModal from './CreateDistributionsModal'
import DeleteDistributionModal from './DeleteDistributionModal'
import UpdateDistributionModal from './UpdateDistributionModal'
import {
  DOCUMENTS_TYPE_LEGAL_ENTITY,
  INVESTOR_MANAGEMENT_URL,
} from '../../constants'
import EditLegalEntityModal from './EditLegalEntityModal'
import AuditLogModal from './AuditLogModal'
import DeleteModal from '../FundAssets/DeleteModal'
import InvestmentTransactionRowRender, {
  investmentTransactionColumns,
} from './InvestmentTransactionRowRender'
import CreateInvestmentTransactionModal from './CreateInvestmentTransactionModal'
import UpdateInvestmentTransactionsModal from './UpdateInvestmentTransactionsModal'
import OrderV2Table from '../OrderOnboardingV2/OrderV2Table'
import DeleteInvestmentTransactionModal from './DeleteInvestmentTransactionModal'

const tableOptions = (customRowRender: (data: any) => any) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const HoveredTableCell = styled(TableCell)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const legalEntityDocumentsColumns = [
  { name: 'uuid', options: { display: false } },
  { name: 'fileName', label: 'Document Name' },
  { name: 'documentType', label: 'Document Type' },
  { name: 'dateAdded', label: 'Date Added' },
  { name: '', label: '' },
  { name: '', label: '' },
]

const LegalEntityDocumentsCustomRowRender =
  (
    openDeleteLegalEntitiesDocumentModal: (
      document: LegalEntityDocument
    ) => void,
    setDocumentUuid: (uuid: string) => void
  ) =>
  (data: [string, string, string, string]) => {
    const [uuid, fileName, documentType, dateAdded] = data
    const deleteLegalEntitiesDocument = {
      uuid,
      fileName,
      documentType,
      dateAdded,
    }
    return (
      <TableRow key={uuid}>
        <TableCell>{fileName}</TableCell>
        <TableCell>{documentType}</TableCell>
        <TableCell>{formatDateString(dateAdded)}</TableCell>
        <HoveredTableCell
          onClick={() =>
            openDeleteLegalEntitiesDocumentModal(deleteLegalEntitiesDocument)
          }
        >
          <DeleteOutlineIcon />
        </HoveredTableCell>
        <HoveredTableCell
          onClick={() => {
            setDocumentUuid(uuid)
          }}
        >
          <DownloadSimple size="1.25rem" />
        </HoveredTableCell>
      </TableRow>
    )
  }

const legalEntityDistributionsColumns = [
  { name: 'amountCents', label: 'Amount' },
  { name: 'distributionDate', label: 'Date' },
  { name: 'isReinvestment', label: 'Type' },
  { name: 'uuid', label: ' ' },
]

const LegalEntityDistributionsCustomRowRender =
  (
    openUpdateDistribution: (existingDistribution: Distribution) => void,
    openDeleteDistribution: (existingDistribution: Distribution) => void
  ) =>
  (data: [number, string, boolean, string]) => {
    const [amountCents, distributionDate, isReinvestment, uuid] = data
    const distribution: Distribution = {
      amountCents,
      distributionDate,
      isReinvestment,
      uuid,
    }
    return (
      <TableRow>
        <TableCell>{formatCents(amountCents)}</TableCell>
        <TableCell>{formatDateString(distributionDate)}</TableCell>
        <TableCell>
          {isReinvestment === true ? 'Reinvestment' : 'Cash'}
        </TableCell>
        <TableCell>
          <EditIcon onClick={() => openUpdateDistribution(distribution)} />
          <DeleteOutlineIcon
            onClick={() => openDeleteDistribution(distribution)}
          />
        </TableCell>
      </TableRow>
    )
  }

const PageTitleContainer = styled('div')({
  marginTop: '3rem',
  marginBottom: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndividualLegalEntityPage = (_: RouteComponentProps) => {
  const [editLegalEntityModalOpen, setEditLegalEntityModalOpen] =
    useState<boolean>(false)

  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false)
  const [
    createInvestmentTransactionsModalOpen,
    setCreateInvestmentTransactionModalOpen,
  ] = useState<boolean>(false)
  const [
    updateInvestmentTransactionsModalOpen,
    setUpdateInvestmentTransactionsModalOpen,
  ] = useState<boolean>(false)
  const [
    deleteInvestmentTransactionModalOpen,
    setDeleteInvestmentTransactionModalOpen,
  ] = useState<boolean>(false)
  const [existingInvestmentTransaction, setExistingInvestmentTransaction] =
    useState<InvestmentTransaction>()
  const [auditLogOpen, setAuditLogOpen] = useState<boolean>(false)
  const [distributionsModalOpen, setDistributionsModalOpen] =
    useState<boolean>(false)
  const [updateDistributionModalOpen, setUpdateDistributionModalOpen] =
    useState<boolean>(false)
  const [deleteDistributionModalOpen, setDeleteDistributionModalOpen] =
    useState<boolean>(false)
  const [existingDistribution, setExistingDistribution] =
    useState<Distribution>()
  const [
    deleteLegalEntitiesDocumentModalOpen,
    setDeleteLegalEntitiesDocumentModalOpen,
  ] = useState<boolean>(false)
  const [existingDocument, setExistingDocument] =
    useState<LegalEntityDocument>()
  const [documentUuid, setDocumentUuid] = useState('')

  const openUpdateInvestmentTransactionsModal = (
    investmentTransaction: InvestmentTransaction
  ) => {
    setUpdateInvestmentTransactionsModalOpen(true)
    setExistingInvestmentTransaction(investmentTransaction)
  }

  const openDeleteInvestmentTransactionsModal = (
    investmentTransaction: InvestmentTransaction
  ) => {
    setDeleteInvestmentTransactionModalOpen(true)
    setExistingInvestmentTransaction(investmentTransaction)
  }

  const openUpdateDistributionModal = (distribution: Distribution) => {
    setExistingDistribution(distribution)
    setUpdateDistributionModalOpen(true)
  }

  const openDeleteDistributionModal = (distribution: Distribution) => {
    setExistingDistribution(distribution)
    setDeleteDistributionModalOpen(true)
  }

  const openDeleteLegalEntitiesDocumentModal = (
    document: LegalEntityDocument
  ) => {
    setExistingDocument(document)
    setDeleteLegalEntitiesDocumentModalOpen(true)
  }

  const closeDeleteLegalEntitiesDocumentModal = () => {
    setDeleteLegalEntitiesDocumentModalOpen(false)
  }
  const closeDocumentModal = () => {
    setDocumentModalOpen(false)
  }
  const closeDistributionsModal = () => {
    setDistributionsModalOpen(false)
  }
  const closeUpdateDistributionModal = () => {
    setUpdateDistributionModalOpen(false)
  }
  const closeDeleteDistributionModal = () => {
    setDeleteDistributionModalOpen(false)
  }
  const closeCreateInvestmentTransactionsModal = () => {
    setCreateInvestmentTransactionModalOpen(false)
  }
  const closeUpdateInvestmentTransactionsModal = () => {
    setUpdateInvestmentTransactionsModalOpen(false)
  }
  const closeDeleteInvestmentTransactionModal = () => {
    setDeleteInvestmentTransactionModalOpen(false)
  }

  const { investorAccountUuid, legalEntityUuid } = useParams()
  const { notify } = useSnackbar()

  const { loading: legalEntityLoading, data: legalEntityData } = useQuery(
    AdminGetLegalEntityDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
      onError: () => {
        notify('Failed to get legal entity data', 'error')
      },
    }
  )

  const { loading: documentsLoading, data: documentsData } = useQuery(
    AdminGetDocumentsDocument,
    {
      variables: {
        input: {
          sourceUuid: legalEntityUuid,
          sourceType: DOCUMENTS_TYPE_LEGAL_ENTITY,
          showHidden: true,
        },
      },
      onError: () => {
        notify('Failed to get legal entity documents', 'error')
      },
    }
  )

  const {
    loading: investmentTransactionsLoading,
    data: investmentTransactionsData,
  } = useQuery(AdminGetInvestmentTransactionInfoForLegalEntityDocument, {
    variables: {
      input: {
        legalEntityUuid,
        includeStaged: true,
      },
    },
    onError: () => {
      notify('Failed to get legal entity investment transactions', 'error')
    },
    onCompleted: () => {},
  })

  const { data: investmentData } = useQuery(
    AdminGetInvestmentInfoForLegalEntityDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
          includeStaged: true,
        },
      },
      onError: () => {
        notify('Failed to get legal entity investment transactions', 'error')
      },
      onCompleted: () => {},
    }
  )

  const pricePerShare =
    investmentData?.getInvestmentInfoForLegalEntity?.legalEntityInvestmentInfo
      ?.pricePerShare || 0
  const totalShareCount =
    investmentData?.getInvestmentInfoForLegalEntity?.legalEntityInvestmentInfo
      ?.totalShareCount || 0
  const totalEquityValue =
    investmentData?.getInvestmentInfoForLegalEntity?.legalEntityInvestmentInfo
      ?.totalEquityValue || 0
  const totalContribution =
    investmentData?.getInvestmentInfoForLegalEntity?.legalEntityInvestmentInfo
      ?.totalContribution || 0

  const { loading: distributionsLoading, data: distributionsData } = useQuery(
    AdminGetLegalEntitiesDistributionsDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
      onError: () => {
        notify('Failed to get legal entity distributions', 'error')
      },
    }
  )

  const { loading: loadingOrders, data: ordersData } = useQuery(
    AdminOrderOnboardingV2SearchOrdersV2Document,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
      onError: () => {
        notify('Failed to get legal entity orders', 'error')
      },
    }
  )

  const [deleteLegalEntitiesDocument] = useMutation(
    AdminDeleteLegalEntitiesDocumentMutationDocument
  )

  const formatLegalEntityDocument = (
    legalEntityDocument: LegalEntityDocument
  ) => ({
    uuid: legalEntityDocument.uuid,
    fileName: legalEntityDocument.fileName,
    documentType: legalEntityDocument.documentType,
    formattedStreet: legalEntityDocument.formattedStreet,
    dateAdded: legalEntityDocument.dateAdded,
  })

  const formatLegalEntityDistribution = (legalEntityDistribution: {
    amountCents: number
    distributionDate: any
    isReinvestment: boolean
    uuid: string
  }) => ({
    amountCents: legalEntityDistribution.amountCents,
    distributionDate: legalEntityDistribution.distributionDate,
    isReinvestment: legalEntityDistribution.isReinvestment,
    uuid: legalEntityDistribution.uuid,
  })

  const legalEntitiesDocuments =
    documentsData?.getDocuments?.legalEntityDocuments?.map(
      formatLegalEntityDocument as any
    ) || []
  const legalEntitiesDistributions =
    distributionsData?.legalEntityDistributions?.distributions?.map(
      formatLegalEntityDistribution as any
    ) || []
  const legalEntitiesInvestmentTransactions =
    investmentTransactionsData?.getInvestmentTransactionInfoForLegalEntity
      ?.investmentTransactionInfo || []

  let legalEntity: any
  if (!legalEntityLoading) {
    legalEntity = legalEntityData?.legalEntity?.legalEntity
  }

  const { refetch: refetchDocument } = useQuery(
    AdminGetDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadDocument = async () => {
    try {
      const urlData = await refetchDocument({
        input: {
          documentUuid,
          legalEntityUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  useEffect(() => {
    if (documentUuid !== '') {
      downloadDocument()
    }
  })

  const links = [
    {
      text: 'Open Audit Log',
      onClick: () => {
        setAuditLogOpen(true)
      },
    },
  ]

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h1">Legal Entity Management</Typography>
        <div>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`${INVESTOR_MANAGEMENT_URL}/${investorAccountUuid}`)
            }
            sx={{ marginRight: '0.5rem' }}
          >
            Return to Investor
          </Button>
          <PopoverMenu
            text="Links"
            buttonProps={{
              variant: 'outlined',
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={links}
          />
          <Button
            variant="contained"
            onClick={() => setEditLegalEntityModalOpen(true)}
            sx={{ marginLeft: '0.5rem' }}
          >
            Edit
          </Button>
        </div>
      </PageTitleContainer>
      {legalEntityLoading ? (
        <LoadingCard text="" />
      ) : (
        <div>
          <Typography variant="body1">{legalEntity.name}</Typography>
          <Typography variant="body1">{legalEntity.email}</Typography>
          <Typography variant="body1">
            {formatPhoneNumber(legalEntity.phoneNumber)}
          </Typography>
          <Typography variant="body1">
            {legalEntity.address?.formattedAddress &&
              `Primary Mailing Address: ${legalEntity.address.formattedAddress}`}
          </Typography>
          <Typography variant="body1">
            Date Joined: {formatDateString(legalEntity.dateJoined)}
          </Typography>
          <Typography>
            Cash Flow Allotment:{' '}
            {parseFloat(legalEntity.cashFlowAllotmentDouble).toFixed(2)}
          </Typography>
          <Typography>
            Cash Flow Selection:{' '}
            {parseFloat(legalEntity.cashFlowSelectionDouble).toFixed(2)}
          </Typography>
          <Typography>
            Holding Expiry Date:{' '}
            {legalEntity.holdingExpiryDate !== '0001-01-01T00:00:00.000Z'
              ? formatDateString(legalEntity.holdingExpiryDate)
              : 'N/A'}
          </Typography>
        </div>
      )}
      <PageTitleContainer>
        <Typography variant="h2">Orders</Typography>
      </PageTitleContainer>
      {loadingOrders ? (
        <CircularProgress size={32} />
      ) : (
        <OrderV2Table
          orders={ordersData?.searchOrdersV2?.orders as Core_OrderV2[]}
        />
      )}
      <PageTitleContainer>
        <Typography variant="h2">Investment Summary</Typography>
      </PageTitleContainer>

      <Grid container spacing={2}>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">Total Equity Value</Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">Total Contributed Amount</Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">Total Share Count</Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">Price Per Share</Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">
            {' '}
            {formatIntegerCents(totalEquityValue)}{' '}
          </Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">
            {' '}
            {formatIntegerCents(totalContribution)}{' '}
          </Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">
            {' '}
            {formatIntegerCents(totalShareCount, false)}{' '}
          </Typography>
        </Grid>
        <Grid xs={3} item container alignItems="stretch">
          <Typography variant="h3">
            {' '}
            {formatIntegerCents(pricePerShare)}
          </Typography>
        </Grid>
      </Grid>

      <PageTitleContainer>
        <Typography variant="h2">Investment Transactions</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setCreateInvestmentTransactionModalOpen(true)
          }}
        >
          Create Investment Transaction
        </Button>
      </PageTitleContainer>
      {investmentTransactionsLoading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={
            legalEntitiesInvestmentTransactions as Core_InvestmentTransaction[]
          }
          columns={investmentTransactionColumns}
          options={
            tableOptions(
              InvestmentTransactionRowRender(
                openUpdateInvestmentTransactionsModal,
                openDeleteInvestmentTransactionsModal
              )
            ) as any
          }
        />
      )}
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <PageTitleContainer>
            <Typography variant="h2">Distributions</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setDistributionsModalOpen(true)
              }}
            >
              Create Distribution
            </Button>
          </PageTitleContainer>
          {distributionsLoading ? (
            <CircularProgress size={32} />
          ) : (
            <DataTable
              title=""
              data={legalEntitiesDistributions as Core_Distribution[]}
              columns={legalEntityDistributionsColumns}
              options={
                tableOptions(
                  LegalEntityDistributionsCustomRowRender(
                    openUpdateDistributionModal,
                    openDeleteDistributionModal
                  )
                ) as any
              }
            />
          )}
        </Grid>
      </Grid>
      <PageTitleContainer>
        <Typography variant="h2">Legal Entity Documents</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setDocumentModalOpen(true)
          }}
        >
          Create Document for Legal Entity
        </Button>
      </PageTitleContainer>
      {documentsLoading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={legalEntitiesDocuments as Core_LegalEntityDocument[]}
          columns={legalEntityDocumentsColumns}
          options={
            tableOptions(
              LegalEntityDocumentsCustomRowRender(
                openDeleteLegalEntitiesDocumentModal,
                setDocumentUuid
              )
            ) as any
          }
        />
      )}
      <CreateDocumentModal
        sourceUuid={legalEntityUuid}
        sourceType={DOCUMENTS_TYPE_LEGAL_ENTITY}
        isOpen={documentModalOpen}
        close={closeDocumentModal}
      />
      <CreateInvestmentTransactionModal
        legalEntityUuid={legalEntityUuid}
        isOpen={createInvestmentTransactionsModalOpen}
        close={closeCreateInvestmentTransactionsModal}
      />
      <UpdateInvestmentTransactionsModal
        existingInvestmentTransaction={existingInvestmentTransaction}
        isOpen={updateInvestmentTransactionsModalOpen}
        close={closeUpdateInvestmentTransactionsModal}
      />
      <DeleteInvestmentTransactionModal
        existingInvestmentTransaction={existingInvestmentTransaction}
        isOpen={deleteInvestmentTransactionModalOpen}
        close={closeDeleteInvestmentTransactionModal}
      />
      <CreateDistributionsModal
        legalEntityUuid={legalEntityUuid}
        isOpen={distributionsModalOpen}
        close={closeDistributionsModal}
      />
      <UpdateDistributionModal
        existingDistribution={existingDistribution}
        isOpen={updateDistributionModalOpen}
        close={closeUpdateDistributionModal}
      />
      <DeleteDistributionModal
        existingDistribution={existingDistribution}
        isOpen={deleteDistributionModalOpen}
        close={closeDeleteDistributionModal}
      />
      <DeleteModal
        displayHeaders={['Document Name', 'Document Type', 'Date Added']}
        displayCells={[
          existingDocument?.fileName || '',
          existingDocument?.documentType || '',
          new Date(existingDocument?.dateAdded || '').toLocaleDateString(
            'en-US'
          ),
        ]}
        onClick={async () => {
          try {
            const deleteLegalEntitiesDocumentInput = {
              documentUuid: existingDocument!.uuid as string,
            }
            await deleteLegalEntitiesDocument({
              variables: {
                deleteLegalEntitiesDocumentInput,
              },
              refetchQueries: [AdminGetDocumentsDocument],
            })
            notify('Successfully deleted document', 'success')
            closeDeleteLegalEntitiesDocumentModal()
          } catch (e) {
            notify('Failed to Delete document', 'error')
          }
        }}
        isOpen={deleteLegalEntitiesDocumentModalOpen}
        close={closeDeleteLegalEntitiesDocumentModal}
      />
      <EditLegalEntityModal
        legalEntity={legalEntity}
        isOpen={editLegalEntityModalOpen}
        close={() => setEditLegalEntityModalOpen(false)}
      />
      <AuditLogModal
        open={auditLogOpen}
        onClose={() => setAuditLogOpen(false)}
        entityUuid={legalEntityUuid}
      />
    </>
  )
}

export default IndividualLegalEntityPage
